import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import OrderInfo from "./OrderInfo";
import Stripe from "./paymentMethods/stripe";
import Zelle from "./paymentMethods/zelle";
import { useParams, useNavigate } from "react-router-dom";
import { verifyJWT } from "../../utils";
import "./Payment.css";
import SelectPayment from "./paymentMethods/SelectPayment";
import Pagomovil from "./paymentMethods/pagomovil";
import C2P from "./paymentMethods/c2p";
import TransferBs from "./paymentMethods/transferBs";
import TransferUSD from "./paymentMethods/transferUSD";
// import Cash from "./paymentMethods/cash";
import API from "../../services";

const Payment = () => {
  const navigate = useNavigate();
  const { JWT } = useParams();

  const [method, setMethod] = useState("");
  const [user, setUser] = useState(null);
  const [payment, setPayment] = useState(null);
  const [auth, setAuth] = useState(null);
  const [localBankEnabledGateway, setLocalBankEnabledGateway] = useState("");

  let availableGateways = [
    { name: "bancaribe", enabled: false },
    { name: "bancamiga", enabled: false },
    { name: "stripe", enabled: true }
  ];

  const fetchPrices = async (payment) => {
    const { data } = await API.odoo.getAmounts(payment);
    return data;
  };

  const decodeJWT = async (jwt) => {
    try {
      const { payload } = await verifyJWT(jwt);
      sessionStorage.setItem("jwt", jwt);
      const response = await API.base.checkToken({ token: jwt });
      if (response.data) {
        navigate("/usedToken");
      }

      const odooPaymentData = await fetchPrices(payload.payment);
      setPayment({
        ...odooPaymentData,
        amount: Number(odooPaymentData.amount.toFixed(2)),
        balance: Number(payload.payment.balance?.toFixed(2)),
        paidElements: odooPaymentData.paidElements.map((element) => ({
          ...element,
          amount: Number(element.amount.toFixed(2)),
        })),
      });
      setUser(payload.user);
      setAuth(payload.auth);
      if (payload.redirect_url) {
        sessionStorage.setItem("url", payload.redirect_url);
      }
    } catch (error) {
      navigate("/forbidden");
    }
  };

  useEffect(() => {
    decodeJWT(JWT);
  }, [JWT]);

  const getAvailableGateways = async () => {
    const { data } = await API.base.getAvailableGateways();
    return data;
  };

  useEffect(() => {
    const initAvailableGateways = async () => {
      try {
        availableGateways = await getAvailableGateways();
        const enabledGateways = availableGateways.find(
          gateway => (gateway.name === "bancaribe" || gateway.name === "bancamiga") && gateway.enabled
        );
        setLocalBankEnabledGateway(enabledGateways);
      } catch (error) {
        setLocalBankEnabledGateway([
          { name: "bancaribe", enabled: false },
          { name: "bancamiga", enabled: false },
        ]);
      }
    };
    initAvailableGateways();
  }, []);

  return (
    <Container
      className="bg-white my-4 shadow-sm h-100"
      style={{ maxWidth: "1050px" }}
    >
      {user && payment && (
        <Row xs={1} lg={2}>
          <Col className="left-col border-end p-4" style={{ height: 600 }}>
            <OrderInfo user={user} payment={payment} />
          </Col>
          <Col
            className="p-4 d-flex flex-column justify-content-center"
            style={{ height: 600 }}
          >
            {{
              zelle: (
                <Zelle setMethod={setMethod} user={user} payment={payment} />
              ),
              stripe: (
                <Stripe
                  setMethod={setMethod}
                  user={user}
                  payment={payment}
                  auth={auth}
                />
              ),
              pagomovil: (
                <Pagomovil
                  setMethod={setMethod}
                  user={user}
                  payment={payment}
                  enabledGateway={localBankEnabledGateway}
                />
              ),
              c2p: <C2P setMethod={setMethod} user={user} payment={payment} />,
              transferBs: (
                <TransferBs
                  setMethod={setMethod}
                  user={user}
                  payment={payment}
                />
              ),
              transferUSD: (
                <TransferUSD
                  setMethod={setMethod}
                  user={user}
                  payment={payment}
                />
              ),
              // cash: <Cash setMethod={setMethod} user={user} payment={payment} /> IN REVISION
            }[method] || (
              <SelectPayment
                confirmMethod={setMethod}
                user={user}
                payment={payment}
                auth={auth}
                enabledGateway={localBankEnabledGateway}
              />
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Payment;
