import React, { useEffect } from "react";
import PropTypes from "prop-types";
import API from "../../../services";
import { Form, Card, Button, Spinner, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const SelectPayment = ({
  confirmMethod,
  user,
  payment,
  auth,
  enabledGateway,
}) => {
  const navigate = useNavigate();
  const [stripeCards, setStripeCards] = useState([]);
  const [method, setMethod] = useState(null);
  const [cardId, setCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState({ element: null, loading: false });
  const [show, setShow] = useState(false);

  const localBankImage =
    enabledGateway && enabledGateway.name ? `${enabledGateway.name}.png` : null;

  const paymentMethods = [
    {
      value: "stripe",
      active: true,
      name: "Tarjeta Internacional",
      image: "stripe.png",
    },
    {
      value: "zelle",
      active: true,
      name: "Zelle",
      image: "zelle-logo.png",
    },
    {
      value: "pagomovil",
      active: true,
      name: "PagoMóvil",
      image: localBankImage,
    },
    {
      value: "c2p",
      active: false,
      name: "C2P",
      image: "pagomovil.png",
    },
    {
      value: "transferBs",
      active: true,
      name: "Transferencia Bs.",
      image: localBankImage,
    },
    {
      value: "transferUSD",
      active: true,
      name: "Transferencia USD",
      image: "truist.png",
    },
    {
      value: "pos",
      active: false,
      name: "Punto de Venta",
      image: "pos.png",
    },
    {
      value: "cash",
      active: false,
      name: "Efectivo",
      image: "cash.svg",
    },
  ];

  const fetchStripeMethods = async () => {
    const { data } = await API.stripe.getPaymentMethods(user.id);
    setStripeCards(data);
  };

  const processPayment = async () => {
    setLoading(true);
    let res;
    if (method == "card") {
      res = await API.stripe.createPaymentIntent({
        paymentMethodId: cardId,
        user,
        payment,
      });
    } else if (method == "pos") {
      res = await API.base.createPayment({
        user,
        payment,
        method: "pos",
        data: { ref: "Pos" },
      });
    } else if (method == "cash") {
      res = await API.base.createPayment({
        user,
        payment,
        method: "cash",
        data: { ref: "Cash" },
      });
      setShow(false);
    }
    if (res.status === 200) {
      navigate("/success", {
        state: { method: method == "card" ? "stripe" : method, payment },
      });
    }
    setLoading(false);
  };

  const deleteCard = async (cardId, i) => {
    setDeleting({ element: i, loading: true });
    await API.stripe.deletePaymentMethod(cardId);
    await fetchStripeMethods();
    setDeleting({ element: null, loading: false });
  };

  useEffect(() => {
    if (auth) {
      fetchStripeMethods();
    }
  }, []);

  const methodCard = () =>
    paymentMethods
      .filter((cardMethod) => cardMethod.active)
      .map((cardMethod, i) => (
        <div
          className="d-flex align-items-center justify-content-center"
          key={i}
        >
          <Card
            className="mb-2 mx-3"
            onClick={() => {
              setMethod(cardMethod.value);
              setCardId(null);
            }}
            style={{
              width: "300px",
              cursor: "pointer",
              ...(method == cardMethod.value && {
                borderColor: "rgba(0,89,228)",
                background: "rgba(0,89,228, 0.3)",
                transition: "all 0.2s",
              }),
            }}
          >
            <Card.Body
              className="d-flex justify-content-start align-items-center"
              style={{ height: "60px" }}
            >
              <img
                className="me-4"
                src={cardMethod.image}
                alt={cardMethod.name}
                width={30}
                height={30}
              />
              <div>{cardMethod.name}</div>
            </Card.Body>
          </Card>
        </div>
      ));

  const stripeCard = () =>
    stripeCards.map((cardMethod, i) => (
      <div className="d-flex align-items-center justify-content-center" key={i}>
        <Card
          className="mb-2 mx-3"
          onClick={() => {
            setMethod("card");
            setCardId(cardMethod.id);
          }}
          style={{
            width: "300px",
            cursor: "pointer",
            ...(cardId == cardMethod.id && {
              borderColor: "rgba(0,89,228)",
              background: "rgba(0,89,228, 0.3)",
              transition: "all 0.2s",
            }),
          }}
        >
          <Card.Body
            className="d-flex justify-content-start align-items-center"
            style={{ height: "60px" }}
          >
            <img
              className="me-5"
              src={`${cardMethod.card.brand}.png`}
              alt={cardMethod.name}
              width={30}
              height={18}
            />
            <div>****{cardMethod.card.last4}</div>
            <Button
              size="sm"
              variant="danger"
              disabled={deleting.element === i && deleting.loading}
              className="ms-auto"
              onClick={() => {
                deleteCard(cardMethod.id, i);
              }}
            >
              {deleting.element === i && deleting.loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <i className="bi bi-trash fs-6"></i>
              )}
            </Button>
          </Card.Body>
        </Card>
      </div>
    ));

  return (
    <div>
      <p>Selecciona tu método de pago:</p>
      <Form className="mb-3">
        <div style={{ overflow: "auto", height: "350px" }}>
          {paymentMethods.find((method) => method.value == "stripe").active &&
            stripeCard()}
          {methodCard()}
        </div>
      </Form>
      {["card", "pos", "cash"].includes(method) ? (
        <Button
          disabled={!method}
          style={{ width: "50%" }}
          onClick={() => setShow(true)}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <>Pagar</>
          )}
        </Button>
      ) : (
        <Button
          disabled={!method}
          style={{ width: "50%" }}
          onClick={() => confirmMethod(method)}
        >
          Continuar
        </Button>
      )}
      <Modal show={show} centered onHide={() => setShow(false)}>
        <Modal.Body>
          ¿Desea concretar la compra con este método de pago?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Atras
          </Button>
          <Button variant="primary" onClick={() => processPayment()}>
            Pagar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

SelectPayment.propTypes = {
  confirmMethod: PropTypes.func,
  user: PropTypes.any,
  payment: PropTypes.any,
  auth: PropTypes.bool,
  enabledGateway: PropTypes.shape({
    name: PropTypes.string,
    enabled: PropTypes.bool,
  }),
};
export default SelectPayment;
