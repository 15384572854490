import React, { useState } from "react";
import {
  CloseButton,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Spinner,
  Alert,
  Collapse,
} from "react-bootstrap";
import { formatter, banks } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import API from "../../../../services";
import PropTypes from "prop-types";
import { useEffect } from "react";

const Pagomovil = ({ setMethod, user, payment }) => {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    phone: "",
    phonePrefix: "",
    bank: "",
  });
  const [paymentDone, setPaymentDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (name == "phone") {
      value = value.replace(/\D/g, "");
      value = value.slice(0, 7);
    }

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const pagomovilData = {
      bank: values.bank,
      phone: values.phonePrefix + values.phone,
    };
    const { data } = await API.bancamiga.findPagomovil({
      user,
      payment,
      pagomovilData,
    });
    if (data.found === true) {
      navigate("/success");
    } else if (data.found === false) {
      setOpen(true);
    } else if (data.found === "noMatch") {
      navigate("/paymentNotFound");
    }
    setLoading(false);
  };

  const banksOptions = () =>
    banks.map((bank) => (
      <option value={bank.id} key={bank.id}>
        {bank.name}
      </option>
    ));

  const prefixOptions = () =>
    phonePrefixes.map((prefix) => (
      <option value={prefix.value} key={prefix.value}>
        {prefix.label}
      </option>
    ));

  const verifyData = () =>
    !paymentDone ||
    Object.values(values).some((value) => value === "") ||
    values.phone.length != 7;

  const fetchPagomovilData = async () => {
    const { data } = await API.base.getPagomovilData(user.id);
    setValues({
      phone: data.phone.slice(5, 12),
      phonePrefix: data.phone.slice(0, 5) || "58414",
      bank: data.bank,
    });
  };

  useEffect(() => {
    fetchPagomovilData();
  }, []);

  const phonePrefixes = [
    {
      label: "0414",
      value: "58414",
    },
    {
      label: "0424",
      value: "58424",
    },
    {
      label: "0412",
      value: "58412",
    },
    {
      label: "0416",
      value: "58416",
    },
    {
      label: "0426",
      value: "58426",
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <div className="d-flex">
        <CloseButton onClick={() => setMethod("")} />
      </div>
      <Row>
        <Col className="d-flex flex-column justify-content-center">
          <p className="mb-0">
            Realiza el Pagomovil por el monto total:{" "}
            <span className="fw-bold">
              {formatter.format(payment.amountBs)} Bs.
            </span>{" "}
            a:
          </p>
          <p className="fw-bold mb-0">J313141321</p>
          <p className="fw-bold mb-0">04242908637</p>
          <p className="fw-bold mb-4">Bancamiga Banco Universal, C.A.</p>
          <p className="mb-0">
            Una vez realizado el pago, complete el siguiente formulario:{" "}
          </p>
          <Form className="m-4" onSubmit={(e) => handleSubmit(e)}>
            <Form.Group className="text-start">
              <Form.Label>Número de teléfono emisor</Form.Label>
              <InputGroup className="mb-3">
                <Form.Select
                  name="phonePrefix"
                  value={values.phonePrefix}
                  style={{ maxWidth: "20%" }}
                  onChange={(e) => handleInputChange(e)}
                >
                  {prefixOptions()}
                </Form.Select>
                <Form.Control
                  name="phone"
                  value={values.phone}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="0001111"
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="text-start">
              <Form.Label>Banco de Origen</Form.Label>
              <Form.Select
                className="mb-3"
                name="bank"
                value={values.bank}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Seleccione banco de origen</option>
                {banksOptions()}
              </Form.Select>
            </Form.Group>
            <Form.Check
              className="mb-3 text-start"
              type="checkbox"
              label="Ya realicé el pago"
              checked={paymentDone}
              onChange={(e) => setPaymentDone(e.target.checked)}
            />
            <Button
              disabled={verifyData()}
              variant="primary"
              type="submit"
              style={{ width: "50%" }}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>Confirmar Pagomóvil</>
              )}
            </Button>
          </Form>
        </Col>
      </Row>

      <Collapse
        style={{ position: "absolute", top: "30%", width: "100%" }}
        in={open}
      >
        <div>
          <Alert variant="danger" onClose={() => setOpen(false)} dismissible>
            <div>
              El pagomóvil no ha sido encontrado, asegurese de haber realizado
              el pago.
              <br />
              Si ya ha realizado el pago, espere unos segundos e intente
              nuevamente.
            </div>
          </Alert>
        </div>
      </Collapse>
    </div>
  );
};

Pagomovil.propTypes = {
  user: PropTypes.any.isRequired,
  payment: PropTypes.any.isRequired,
  setMethod: PropTypes.func,
};

export default Pagomovil;
