import stripe from "./stripe";
import base from "./base";
import bancamiga from "./bancamiga";
import bancaribe from "./bancaribe";
import odoo from "./odoo";

export default {
  stripe,
  base,
  bancamiga,
  bancaribe,
  odoo
};
