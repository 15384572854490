import React from "react";
import PropTypes from "prop-types";
import bancaribe from "./bancaribe";
import bancamiga from "./bancamiga";

const Pagomovil = ({ setMethod, user, payment, enabledGateway }) => {
  const bankComponents = {
    bancaribe,
    bancamiga,
  };

  const BankComponent = bankComponents[enabledGateway.name];

  return (
    <>
      {enabledGateway && BankComponent && (
        <div>
          <BankComponent user={user} payment={payment} setMethod={setMethod} />
        </div>
      )}
    </>
  );
};

Pagomovil.propTypes = {
  user: PropTypes.any.isRequired,
  payment: PropTypes.any.isRequired,
  setMethod: PropTypes.func,
  enabledGateway: PropTypes.shape({
    name: PropTypes.string,
    enabled: PropTypes.bool,
  })
};

export default Pagomovil;
